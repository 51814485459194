.AIMarketingContentGenerator-wrapper {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }

  h3 {
    font-size: 18px;
    margin-top: 10px;
    color: #555;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  textarea {
    resize: vertical;
    height: 150px;
  }

  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }

  button:hover {
    background-color: #0056b3;
  }

  .loading {
    text-align: center;
    color: #888;
  }

  .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: end;
  }

  .error {
    color: red;
  }

  /* AIMarketingContentGenerator.css */
  .generating-message {
    font-size: 18px;
    color: #333;
    animation: blink 1.5s infinite;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
