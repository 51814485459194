.logos {
  background-position: center;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 5%;
  border-radius: 50%;
}
.units-cards-wrapper {
  @include d-flex-h-center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0.5rem;
  .container-wrapper {
    height: 100%;
    flex-wrap: wrap;
    display: contents;
  }

  .card-checkbox-wrapper {
    top: 0.1rem;
  }
  .units-card-wrapper {
    .above-market {
      display: flex;
      align-items: center;
      color: #ce4f2f;
      display: flex;
      padding: 2px 8px;
      align-items: center;
      border-radius: 9999px    ;
      border:  #ce4f2f solid 1px; 

     // background: #fef6ee;
    }
    .less-than-market {
      display: flex;
      align-items: center;
      color: #b3d984;
      display: flex;
      padding: 2px 8px;
      align-items: center;
      border-radius: 9999px;
     // background: #f4f1ed;
      border:  #b3d984  solid 1px; 

    }
    .within-market {
      display: flex;
      align-items: center;
      color: #a28200;
      display: flex;
      padding: 2px 8px;
      align-items: center;
      border-radius: 9999px;
     // background: #eef4ff;
      border:  #a28200 solid 1px;

    }

    .ribbon-wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 2;
      margin-right: 2.4%;
      right: -3%;
      min-width: 18%;
      height: 4%;
      width: 70px;
    }

    .ribbon {
      position: relative;
      cursor: pointer;
      // /* z-index: 2; */
      padding: 0px 4px;
      margin-right: 2.4%;
      // /* border-top-left-radius: 2px; */
      // /* border-bottom-left-radius: 2px; */
      font-size: 10px;
      line-height: 18px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      // /* text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); */
      // /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); */
      // /* zoom: 1; */
      top: 2%;
      // /* right: -3%; */
      // /* min-width: 10%; */
      // /* height: 18px;
    }

    .gray {
      background: #aaaaaa;
    }
    .green {
      background: #66b94d;
    }

    .with-bulk {
      background: #3b72d9;
      top: 9%;
    }
    .portals-ribbon {
      background: #417092;
      top: 16%;
    }
    .top-ribbon {
      top: 9.2%;
    }
    .with-bulk-top {
      top: 16.2% !important;
      background-color: var(--c-primary);
    }
    // .portals-ribbon-top {
    //   top: 22.2% !important;
    //   background-color: #417092;
    // }
    .ribbon:after {
      content: "";
      position: absolute;
      z-index: -20;
      top: 100%;
      left: auto;
      border-style: solid;
      border-width: 0 0 10px 10px;
      right: 0;
    }
    .gray:after {
      border-color: transparent #666666;
    }
    .green:after {
      border-color: transparent #3e7030;
    }
    .with-bulk:after {
      border-color: transparent #214280;
    }
    .portals-ribbon:after {
      border-color: transparent #00063b;
    }
    .no-ribbon {
      height: 41px;
      position: absolute;
      z-index: 2000;
    }
    .cards-wrapper {
      overflow: hidden;
      height: 100%;
      @include d-flex-column;
      position: relative;
      border: 1px solid $c-gray-secondary;
      border-radius: 10px;
      color: $c-black;
      .cover-image {
        background-color: $c-gray-lighter;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 75px;
        width: 105px;
        border-radius: 0.5rem;
      }
      .cards-header-wrapper {
        @include d-flex-column;
        flex: 1 1 auto;
        padding: 0.5rem 0.5rem 0;

        .header-title-wrapper {
          @include d-flex-v-center;
          padding: 0 0.5rem;
          margin-bottom: 0.1rem;
          width: 100%;
          .header-title {
            font-size: $fz-16px;
            font-weight: $fw-simi-bold;
          }
        }
      }
      .flat-contents-wrapper {
        @include d-flex-v-center;
        width: 100%;
        min-width: 310px;
        flex-wrap: wrap;
        .flat-content-item {
          @include d-inline-flex-v-center;
          margin: 0 0.25rem 0.5rem;
          padding: 0.1rem 0.5rem;
          border-radius: 1rem;
          background-color: $c-gray-lighter;
          color: $c-black;
        }
      }
      .cards-body-wrapper {
        justify-content: flex-start;
        width: 100%;
        padding: 1rem 0 0;
        flex: 1 1 auto;
        .body-title-wrapper {
          padding: 0 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 0.1rem;
          .body-title {
            color: $c-black;
            font-size: $fz-14px;
            font-weight: $fw-simi-bold;
            padding-bottom: 0.5rem;
            width: 66%;
            max-width: 10rem;
            max-height: 40px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .body-status {
            width: 100%;
            min-width: 229px;
            border-radius: 0.3rem;
            // padding: 0.5rem;
            font-size: $fz-14px;
            display: flex;
            // .body-status-type {
            //   color: $c-gray-primary;
            // }
          }
        }
        .price-text {
          padding: 0 0.5rem;
          font-size: $fz-16px;
          font-weight: $fw-simi-bold;
          margin-bottom: 0.25rem;
        }
        .card-body-section {
          @include d-flex-column;
          width: 100%;
          padding: 0 0.5rem;
          .body-images-wrapper {
            @include d-flex;
            width: 100%;
            margin-top: 0.5rem;
            .setting-btn {
              background-color: $c-white;
              color: $c-black;
              position: absolute;
              top: 0.5rem;
              left: 0.5rem;
              @include rtl {
                left: initial;
                right: 0.5rem;
              }
            }
            .body-image-item-wrapper {
              width: 100%;
              padding: 0 0.5rem;
              &:nth-child(1) {
                width: 115px;
                max-width: 115px;
                min-width: 115px;
              }
              .body-image-item {
                @include d-inline-flex;
                width: 100%;
                position: relative;
                .body-image-overlay {
                  @include d-flex-center;
                  color: $c-white;
                  position: absolute;
                  left: 0;
                  top: 0;
                  border-radius: 1rem;
                  height: 100%;
                  width: 100%;
                  background-color: rgba(0, 0, 0, 0.35);
                }
              }
            }
          }
          .body-item-wrapper {
            @include d-flex-v-center;
            flex-wrap: wrap;
            width: 100%;
            .body-icon {
              padding: 0 0.5rem;
              vertical-align: middle;
            }
            .body-item {
              @include d-inline-flex-v-center;
            }
            .card-tooltips-wapper {
              @include d-flex-v-center-h-between;
              flex-wrap: wrap;
              .card-tooltip {
                @include d-flex-v-center;
                margin-bottom: 0.25rem;
              }
            }
          }
          .details-item-wrapper {
            @include d-flex;
            width: 100%;
            padding: 0 0.5rem;
            margin-bottom: 0.1rem;
            min-width: 200px;
            max-height: 35px;
            align-self: flex-start;
          }
          .details-text {
            white-space: nowrap;
            white-space: break-spaces;
          }
          .details-icon {
            vertical-align: bottom;
          }
          .details-icon,
          .details-text,
          .body-text {
            font-weight: $fw-bold;
          }
          .details-item {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            @supports (display: -webkit-box) {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          &.is-expanded {
            flex-direction: column;
            flex-wrap: wrap;
            .details-item-wrapper {
              width: 50%;
              max-width: 50%;
              @include phones-max {
                width: 100%;
                max-width: 100%;
              }
            }
            .body-item-wrapper {
              .card-tooltips-wapper {
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: flex-start;
              }
            }
          }
        }
      }
      .cards-progress-wrapper {
        width: 100%;
      }
      .cards-footer-wrapper {
        overflow: hidden;
        width: 100%;
        min-height: 40px;
        padding: 0;
        border-top: 1px solid $c-gray-secondary;
        display: flex;
        justify-content: space-between;

        .btns {
          position: relative;
          border-radius: 0;
          height: 100%;
          min-height: 40px;
          min-width: 50%;
          color: $c-black;
          width: 100%;
          max-width: 33.3333%;
          .mdi:before {
            font-size: $fz-18px;
          }
          &:not(:last-child) {
            border-right: 1px solid $c-gray-secondary;
            @include rtl {
              border-left: 1px solid $c-gray-secondary;
              border-right: none;
            }
          }
        }
        .maxWidth_30 {
          max-width: 32.5%;
          min-height: 20px;
        }

        .maxWidth_50 {
          max-width: 50%;
          min-height: 20px;
        }
        .maxWidth_100 {
          max-width: 100%;
          min-height: 20px;
        }
      }
    }
    &.is-open {
      .cards-wrapper {
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
        color: $c-secondary;
        .cards-footer-wrapper {
          .btns {
            color: $c-secondary;
          }
        }
      }
    }
    position: relative;
    @include d-inline-flex-column;
    padding: 0 0.5rem;
    min-width: 355px;
    @include phones-max {
      min-width: 290px;
    }
    margin-bottom: 1rem;
    width: 20%;
    max-width: 380px;
    @include laptops-xl-max {
      width: 25%;
    }
    @include laptops-l-max {
      width: 33.3333%;
    }

    @include laptops-max {
      width: 50%;
    }

    @include phones-max {
      width: 100%;
      max-width: initial;
    }
    &.is-expanded {
      width: 50%;
      max-width: initial;
      .id-date-wrapper {
        width: 75%;
      }
      // @include laptops-xl-max {
      //   width: 33.3333%;
      // }

      // @include laptops-l-max {
      //   width: 50%;
      // }
      .cards-body-wrapper {
        flex-direction: row;
        align-items: flex-start;
        @include phones-max {
          flex-wrap: wrap;
        }
        // @include tablets-max {
        //   flex-direction: column;
        // }
        .card-body-section {
          width: 66.6667%;
          &:nth-child(1) {
            width: 33.3333%;
            max-width: 33.3333%;
          }
          @include laptops-xxl-max {
            width: 50% !important;
            max-width: 50% !important;
          }
          @include phones-max {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
      }
      @include laptops-max {
        width: 100%;
      }
    }
  }
  .price-wrapper {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0rem;
    font-size: $fz-14px;
    font-weight: bold;
    .Sale {
      color: #31c7aa;
    }
    .Rent {
      color: var(--c-primary);
    }
    .unit-price {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }
    .for-lable {
      padding-right: 0.2rem;
      @include rtl {
        padding-left: 0.2rem;
      }
    }
  }
  .contact-id-wrapper {
    padding-bottom: 0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: $fz-14px;
    min-width: 80px;
    font-weight: bold;
    display: flex;
    align-items: center;
    textarea {
      width: 0;
      height: 0;
      padding: 0;
      opacity: 0;
    }
    .contact-id {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      textarea {
        resize: none;
      }
    }
    .mdi::before {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-size: $fz-14px;
      cursor: pointer;
    }
  }
  .id-date-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include laptops-max {
      justify-content: initial;
    }
    align-items: center;
    .Featured {
      background-color: #e0f0f0 !important;
      color: #09097d;
    }

    .Only {
      background-color: #ede9ed !important;
      color: #630369;
    }

    .Hot {
      background-color: #ffecca !important;
    }
    .Market {
      background-color: #e4ffde !important;
      color: #006b17;
    }
    .Above {
      background-color: #fffddb !important;
      color: black;
    }
  }
  .created-on {
    min-width: 140px;
    .mdi {
      vertical-align: bottom;
      padding-right: 0.5rem;
      @include rtl {
        padding-right: 0;
        padding-left: 0.5rem;
      }
      &::before {
        font-size: $fz-16px;
      }
    }
  }
  .right-list {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding: 1rem;
  }
  .flex-row {
    display: flex;
  }
  .flex-wrapper {
    display: flex;
    max-height: 85px;
  }
}
