.ProposalFormContainer {
    width: 100%;
    display: grid;
    gap: 20px;

    .ProposalForm__field_box {
        display: grid;
        grid-template-columns: minmax(180px, 1fr) 3fr;
        gap: 32px;
    }

    .ProposalForm__field_label>p:first-child {
        color: #344054;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
    }

    .ProposalForm__field_label>p:nth-child(2) {
        color: #475467;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        margin-block: 8px;
    }

    .field_divider {
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #EAECF0;
    }

    .select_counter {
        color: #475467;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        padding-inline: 4px;
    }

}