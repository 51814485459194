.unit-mpi-gauge-chart-view {
  display: flex;
  justify-content: center;
}

///////// end new design 2024 ///////
.GaugeChart {
  position: relative;
}
.chart {
  .psiPrice {
    margin-top: -19px;
    left: -21px;
    position: absolute;
  }
}
.unit-mpi-chart {
  position: absolute;
  display: contents;
  .GaugeChart {
    position: relative;
    // width: 200px;
    // height: 400px;
    // background-color: #ddd;
    // border-radius: 10px;

    // overflow: hidden;
  }
  .chart-sub {
    position: absolute;
    bottom: 0;
    border-top-left-radius: 40px;
    left: 50%;
    width: 5px;
    height: 123px;
    .high {
      color: white;
      position: absolute;
      bottom: 8px;
      left: 90px;
    }

    .high-Price {
      color: white;
      position: absolute;
      bottom: 23px;
      left: 67px;
      text-align: center;
    }

    .low {
      color: white;
      position: absolute;
      bottom: 8px;
      right: 90px;
    }
    .low-minPrice {
      color: white;
      position: absolute;
      bottom: 25px;
      right: 70px;
      text-align: center;
    }
    .AssumedMarketPrice {
      color: #000000;
      position: absolute;
      bottom: -39px;
      right: -77px;
      width: 140px;
      text-align: center;
    }
  }
  .needle {
    position: absolute;
    bottom: 0;
    border-top-left-radius: 40px;
    left: 50%;
    width: 5px;
    height: 128px;
    background-color:#b6b6b6fa;
    transform-origin: bottom center;
    transform: rotate(0deg);
    transition: transform 2s ease-in-out;
  }
   .needle.animate-red {
    animation: moveNeedleRed 2s forwards;
  }

  @keyframes moveNeedleRed {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(60deg);
    }
  }

   .needle.animate-green-after {
    animation: moveNeedleGreenAfter 2s forwards;
  }

  @keyframes moveNeedleGreenAfter {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(-15deg);
    }
  }

   .needle.animate-red-after {
    animation: moveNeedleRedAfter 2s forwards;
  }

  @keyframes moveNeedleRedAfter {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(15deg);
    }
  }

   .needle.animate-green {
    animation: moveNeedleGreen 2s forwards;
  }

  @keyframes moveNeedleGreen {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(-60deg);
    }
  }

 
  .needle.animate-center {
    animation: moveNeedleCenter 2s forwards;
  }

  @keyframes moveNeedleCenter {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(-1.5deg);
    }
  }
}
 