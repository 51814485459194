.view-wrapper {
  width: 100%;

  .header-section {
    @include d-flex-column;
    background-color: $c-white;
    position: relative;
    
    .view-search-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .clear-all-btn {
        margin-top: 5px;
        padding: 0 5px;
        width: 95%;
        .mdi::before {
          font-size: $fz-15px;
        }
      }
      .advance-search {
        display: flex;
        justify-items: flex-start;
        margin-top: 10px;
        color: var(--c-primary);
        display: flex;
        align-items: center;
        .mdi::before {
          font-size: $fz-default;
          @include rtl {
            transform: scaleX(-1);
            margin-left: 5px;
          }
        }
        .is-active-filter {
          background-color: #368750e3 !important;
        }
      }
    }

    .filter-section {
      @include d-flex;
      @include flex-v-center-h-between;
      position: relative;
      width: 100%;

      @include tablets-max {
        flex-direction: column;
      }

      .filter-section-date-picker {
        .date-range-picker-component {
          max-width: unset;
        }

        .date-range-picker-component .display-wrapper {
          border: 1px solid #f0f0f0;
          max-height: 32px;
        }
      }

      .section {
        @include d-inline-flex;
        @include flex-center;
        // @include flex-v-center;
        margin-bottom: 1rem;

        &:first-child {
          justify-content: flex-start;
        }
        &:nth-child(2) {
          min-width: 50%;
        }
        &.autocomplete-section {
          padding: 0 0.5rem;
          align-items: flex-start;
          @include phones-max {
            align-items: center;
          }
        }
        .btns-icon {
          width: 30px;
          height: 30px;
          min-width: 30px;
        }
        @include tablets-max {
          width: 100%;

          &:first-child {
            order: 2;

            .actions-buttons-wrapper {
              @include flex-h-center;
            }
          }

          &:nth-child(2) {
            @include phones-max {
              flex-direction: column;

              .d-flex-column {
                margin-bottom: 1rem;
              }
            }

            order: 1;
          }
        }
      }

      .section-resize {
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: center !important;
        gap: 8px;
        flex: 1 0 25%;
  
        .actions-buttons-wrapper{
          flex: 0 1 auto !important;
        }
      }
    }

    .contacts-hidder-section {
      display: flex;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      align-items: center;
    }
  }

  .body-section {
    @include d-flex;
    @include flex-h-center;
    flex-wrap: wrap;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
}
.filter-type-btn {
  position: absolute !important;
  right: 3.5rem;
  margin: 0;
  @include rtl {
    left: 3.5rem;
    right: initial;
  }
  border-radius: 0rem !important;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
.cards-filter-section {
  @include d-flex-v-center;
  flex-wrap: wrap;
}
.tag-curve-wrapper {
  position: absolute;
  top: 0;
  right: -1px;
  @include rtl {
    left: -1px;
    right: initial;
    transform: scaleX(-1);
  }
  // background-color: $c-white;
}
.card-tooltip {
  @include d-inline-flex;
  background-color: $c-secondary;
  color: $c-white;
  padding: 0.25rem 0.5rem;
  font-weight: $fw-simi-bold;
  margin: 0 0.5rem;
  border-radius: 1rem;
  &.Cold,
  &.Warm,
  &.Hot {
    align-items: center;

    .mdi::before {
      font-size: $fz-default;
      font-weight: 900;
    }
  }

  &.Cold {
    background-color: #eff8ff;
    color: #175cd3;

    .mdi::before {
      color: #2e90fa;
    }
  }

  &.Warm {
    background-color: #fffaeb;
    color: #b54708;

    .mdi::before {
      color: #f79009;
    }
  }

  &.Hot {
    background-color: #fef3f2;
    color: #b42318;

    .mdi::before {
      color: #f04438;
    }
  }
}
.qualified-lead,
.un-qualified-lead,
.qualified-by-qa,
.opportunity-lead {
  font-weight: 600;
  // background-color: #81b64d;
  // color: #fafafa;
  // font-weight: 500;
  border-radius: 66%;
  padding: 5px;
  border-radius: 1rem;
  font-size: 13px;
}

.qualified-lead {
  border: 1px solid #92ce57;
  color: #92ce57;
}
.opportunity-lead {
  border: 1px solid #07a766;
  color: #07a766;
}
.un-qualified-lead {
  border: 1px solid #ec5555;
  color: #ec5555;
}
.qualified-by-qa {
  border: 1px solid #55a3ec;
  color: #557dec;
}
.card-checkbox-wrapper {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  z-index: 1;

  &.trl-15px {
    top: 15px;
    right: 15px;
  }

  @include rtl {
    right: initial;
    left: 1rem;

    &.trl-15px {
      left: 15px;
    }
  }
}
.presentational-wrapper {
  position: relative;
}
.popover-quick-add {
  z-index: 9999 !important;
}
// Start Slider
.slide-images {
  margin-bottom: 1rem;
  position: relative;
  height: 100px;
  width: 100%;
  .slide-image {
    background-color: $c-white;
    position: absolute;
    border-radius: 1rem;
    box-shadow: 0 0 3px 1px rgba($c-black-dark, 0.3);
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      z-index: 999999 !important;
    }
  }
}
// End Slider

.password-validation {
  .mdi {
    flex-direction: row !important;
    align-items: center !important;
    display: flex;
  }
  .mdi-check::before {
    color: #07a766;
    margin: 0 3px;
  }

  .mdi-information-outline::before {
    color: #b42318;
    margin: 0 3px;
  }
}
