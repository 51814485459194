.filter-components {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  > div {
    margin: 0.5rem 1rem;
  }
}

.filter-section {
  .section1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.btn-assign-to-me-first-click {
  width: 50%;
}

.btn-assign-to-me-second-click {
  width: 50%;
  background-color: cadetblue;
}

.Lead-assignment-status-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .Lead-assignment-status-title {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    font-family: Poppins;
    font-style: normal;
    line-height: normal;
  }
  .Lead-assignment-status-content {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    background: rgba(219, 80, 74, 0.08);
    .Lead-assignment-status-sub-title {
      color: #db504a;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .Lead-assignment-status-ids {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      flex-wrap: wrap;
      padding: 10px 0px;
    }
    .Lead-assignment-status-showMore {
      display: flex;
      gap: 4px;
      color: #3b72d9;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      align-self: flex-end;
      padding: 12px 14px;
    }
  }
}
