.PickerCard {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #F2F4F7;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: transparent !important;
    }

    &.PickerCard__checked {
        border: 2px solid #7F6944;
        background-color: transparent !important;
    }


}