.share-unit-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px;
  .share-units-cards {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .share-unit-filter-section1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .share-unit-filter-left {
    display: flex;
    flex-direction: row;
    &[dir='rtl'] {
      flex-direction: row-reverse;
    }
    .type-btn {
      background: #fff;
      min-height: 40px;
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border: 1px solid #d0d5dd;
      &:hover {
        background: #e6f4ff;
        color: #007bff;
      }
      &.selected-btn {
        background: #3b72d9;
        color: #fff;
        font-weight: bold;
        border-color: #3b72d9;
      }
    }
    .rent-btn {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .all-btn {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .share-unit-filter-right {
    display: flex;
    align-items: center;
    gap: 10px;
    .widthAutoComplete {
      .MuiInputBase-root {
        padding: 0px 12px 0px 7px !important;
        min-height: 40px !important;
      }
    }

    .styled-data-picker {
      width: 174px !important;
      height: 40px !important;
      .date-picker-wrapper {
        height: 100%;
      }
    }
    .unit-ref-input {
      width: 301px;
      &.MuiFormControl-root {
        margin-bottom: 0 !important;
      }
    }
    .unit-ref-content {
      .MuiInputBase-root {
        padding: 0px 12px 0px 7px !important;
        min-height: 40px !important;
      }
    }
    .shared-by-input {
      width: 122px;
      &.MuiFormControl-root {
        margin-bottom: 0 !important;
      }
    }
    .shared-by-content {
      .MuiInputBase-root {
        padding: 0px 12px 0px 7px !important;
        min-height: 40px !important;
      }
    }
  }
  .share-units-filter-left {
    display: flex;
    align-items: center;
    gap: 5px;
    .widthAutoComplete {
      .MuiInputBase-root {
        padding: 0px 12px 0px 7px !important;
        min-height: 40px !important;
      }
    }
  }

  .share-unit-filter-section2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    .styledAutoCompleteRef {
      min-width: 0px !important;
      width: 194px;
      height: 40px;
      .autocomplete {
        height: 100%;
      }
    }
  }
  .StyledSelected {
    height: 40px;

    &.MuiFormControl-root {
      .select-body-wrapper {
        height: 100%;
      }
      .unselects {
        height: 100%;
      }
      .selects {
        height: 100%;
      }
    }
  }

  .view-search-wrapper-shared-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    height: 40px;

    .clear-shared {
      width: 20px;
    }
  }
}
