.dropdown-workflow {
  position: absolute;
  top: 0;
  right: 0;
}

.dots-vertical {
  width: 14px;
  height: auto;
  background-size: 17%;
}

.dndflow {
  display: flex;
}

.work-flow-node-desc {
  font-size: 7px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}
.Item-Drag-Work {
  padding: 12px;
  border-bottom-right-radius: 20px;
  background-color: #e5e5f7;
  color: #000;
  opacity: 0.8;
  overflow: auto;
  max-height: 38rem;
  background: repeating-linear-gradient(
        35deg,
        transparent 0 4px,
        #4e8ee67d 4px 5px
      )
      no-repeat 0 0 / 50% 100%,
    repeating-linear-gradient(-35deg, transparent 0 4px, #4e8ee67d 4px 5px)
      no-repeat 100% 0 / 50% 100%;

  width: 196px;

  &.DISBELDp {
    background-color: #cbcbcf;
    color: #000;
    opacity: 0.4;
    cursor: not-allowed !important;
    //  background-image: repeating-radial-gradient(circle at 0 0, #f2f2f2 0, #e5e5f7 10px), repeating-linear-gradient(#f6004b55, #ececec);
  }
}

.dndnode-input {
  color: #0266ff;
  border: 1px solid #ffffff;
  background-color: #ffffffbd;
  padding: 5px;
  border-radius: 8px;
  font-weight: 800;
  opacity: 0.8;

  margin-top: 9px;
  cursor: move;

  &.DISBELDp {
    cursor: not-allowed !important;
    background-color: #cbcbcf;
    color: #000;
    opacity: 0.4;
    background-image: repeating-radial-gradient(
        circle at 0 0,
        #f2f2f2 0,
        #e5e5f7 10px
      ),
      repeating-linear-gradient(#f6004b55, #ececec);
  }
}

.dndnode-input:hover {
  background-color: #e0e0e0;
  /* New background color on hover */
  border: 1px solid #1aabff;
}

.hypnotic {
  width: 88px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 88px;
  border: 3.5px solid #474bff;
  background: conic-gradient(
      from -90deg at calc(100% - 3.5px) calc(100% - 3.5px),
      #0000 0 90deg,
      #474bff 0
    ),
    conic-gradient(
      from -90deg at calc(100% - 3.5px) calc(100% - 3.5px),
      #0000 0 90deg,
      #474bff 0
    );
  background-size: 28.2px 28.2px;
  background-position: 0 0;
  animation: hypnotic-p6wowihg 0.8s infinite;
}

@keyframes hypnotic-p6wowihg {
  100% {
    background-position: -28.2px -28.2px, 28.2px 28.2px;
  }
}

.WorkFlowView-MAIN {
  position: relative;
}

.Popap-Option {
  .MuiButtonBase-root.Mui-disabled {
    color: #3a3939;
    cursor: not-allowed !important;
    background-color: rgba(116, 115, 115, 0.308) !important;
  }
}

.section-header {
  padding-top: 8px;
  padding-bottom: 6px;
  max-width: inherit;
  font-size: 17px;
  text-align: center;
  margin: 0;
  background-color: #edeef0;
}

.section-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 309px;
}

.condition-item,
.action-item {
  width: 100%;
}

.condition-item:hover,
.action-item:hover {
  background-color: #7f6944;
  cursor: pointer !important;
  color: #ffffff;
}

.actionType-item-Action {
  text-align: justify;
}

.Popap-Option-CustomNode {
  max-height: 21rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .MuiButtonBase-root {
    height: 27px;
    width: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 22px;
  }

  .Condition-Option {
    //  background-color: rgba(252, 3, 3, 0.836);
  }

  //  background-color: rgba(236, 236, 236, 0.555);
}

#root
  > div.container
  > div.content-wrapper
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div.react-flow__attribution.top.right {
  display: none;
}

.Drag-sub-text {
  background-color: #00000061;
  height: 21px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 9px;
  color: white;
  font-weight: 600;
}

:root {
  --surface-color: #0082eca8;
  --curve: 40;
}

.cardsWork {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 5vw;
  max-width: 20rem;
  padding: 0;
  list-style-type: none;
}

.card-WORKFLOW {
  position: relative;
  border: 1px solid #eeeeee;
  box-shadow: 11px 13px 8px #5c5c5c40;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  margin: 0 0 0.3em;
  color: #ffffff;
  font-size: 24px;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: 0.8em;
  color: #d7bdca;
}

.card__status {
  font-size: 0.8em;
  color: #ffffff;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #ffffff;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.no-work-flow-found-text {
  font-size: 43px;
  font-weight: 700;
  font-family: "Poppins";
}

.flex-center-work {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.fileds {
  display: flex;
  gap: 15px;
  width: 60rem;
}

.flex-content {
  flex-direction: column;
}

//  .react-flow__node {
//    background-color: white;
//    padding: 10px;
//    border: 1px solid #ccc;
//    border-radius: 3px;
//    font-size: 14px;
//  }

.condition-button {
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  width: 18px;
  height: 18px;
  font-size: 5px;
  z-index: 11;
  top: 43px;
  padding: 3px;
  display: inline-block;
  background-color: #fff;
}

.condition-button.yes {
  border: 2px solid green;
  color: green;
  left: 15%;
}

.condition-button.no {
  border: 2px solid red;
  color: red;
  right: 15%;
}

.dropdown-container {
  cursor: pointer;
  border: 2px solid green;
  border-radius: 50%;
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 11;
  padding: 0;
  display: inline-block;
  color: green;
  background-color: #fff;
  top: 44px;
  right: 44%;
}

.description {
  font-size: 10px;
  color: #666;
  border-bottom: 6px solid;
  border-radius: 4px;
}

.description.condition {
  border-bottom-color: #1087e0;
  /* Red for Condition */
}

.description.default {
  border-bottom-color: #47a318;
  /* Green for default */
}

.workflow-container {
}

.custom-node {
  border: 1px solid #1a192b;
  border-radius: 5px;
  background-color: #fff;
  min-width: 150px;
  max-width: 210px;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  font-family: Arial, sans-serif;
  z-index: 10;

  .Node-Label {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    &.default {
      color: #333;
    }

    &.condition {
      color: #555;
    }
  }

  .dropdown-container {
    position: absolute;
    top: 10px;
    right: 10px;

    .dropdown-workflow {
      img.dots-vertical {
        cursor: pointer;
      }
    }
  }

  .condition-button {
    &.yes {
      color: green;
    }

    &.no {
      color: red;
    }
  }
}

.popover-top-ppp {
  // Customize your popover classes here
}

.top-ppp {
  z-index: 1300;
  /* Ensure it's on top */
}

.react-flow__pane {
  border-top: 1px solid #d5cdcd;
  z-index: 2;
}

.react-flow__edge-text {
  position: absolute;
  background-color: #ffcc00;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
}

.mars-5 {
  margin: 1rem 2rem 1rem 3rem !important;
}

.icon-box-slider {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.icon-box-slider h2 {
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.icon-box-slider p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.icon-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  padding: 20px 0;
}

.icon-box {
  background: #f7f7f7;
  border-radius: 10px;
  width: 120px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.icon-box:hover {
  background-color: #e0e0e0;
  /* Hover color */
}

.icon-box.selected {
  background-color: #a0bfe5;
  /* Selected background color */
  color: #fff;
  /* Selected text color */
}

.icon-box .icon {
  font-size: 36px;
  margin-bottom: 10px;
}

.icon-box .text h4 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.icon-box .text p {
  font-size: 12px;
  margin: 0;
}

.condition-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.card-item--work-flow {
  @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

  // .body-card-item--work-flow {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   min-height: 100vh;
  //   background: #fff;
  //   font-family: "Lato", sans-serif;
  //   font-weight: 400;
  //   font-style: normal;
  // }

  section {
    padding-block: calc(
      min(20vh, 2rem)
    ); // Ensure proper handling of mixed units
    width: calc(min(86.5rem, 90%));
    margin-inline: auto;
    color: #111;
  }

  section h2 {
    text-transform: capitalize;
    letter-spacing: 0.025em;
    font-size: clamp(
      2rem,
      calc(1.8125rem + 0.75vw),
      2.6rem
    ); // Fixed Sass unit incompatibility
  }

  section a {
    display: inline-block;
    text-decoration: none;
  }

  section .container {
    margin-top: 2em;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    gap: 2rem;
  }

  // section .container .card:nth-child(1) .box .icon .iconBox {
  //   background: #d3b19a;
  // }

  section .container .card .box .icon .iconBox {
    background: #70b3b1;
  }

  // section .container .card:nth-child(3) .box .icon .iconBox {
  //   background: #d05fa2;
  // }

  section .container .card ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.625rem;
  }

  section .container .card {
    cursor: pointer;
  }

  section .container .card:hover {
    background-color: #a2d6f621;
    border-radius: 20px;
  }

  section .container .card ul li {
    text-transform: uppercase;
    background: var(--clr-tag);
    color: #282828;
    font-weight: 700;
    font-size: 0.8rem;
    padding: 0.375rem 0.625rem;
    border-radius: 0.188rem;
  }

  section .container .card ul .branding {
    color: #704a31;
  }

  section .container .card ul .packaging {
    color: #1e3938;
  }

  section .container .card ul .marketing {
    color: #4d1637;
  }

  section .container .card .content {
    padding: 0.938rem 0.625rem;
  }

  section .container .card .content h3 {
    text-transform: capitalize;
    font-size: clamp(
      1.5rem,
      calc(1.3909rem + 0.4364vw),
      1.8rem
    ); // Fixed Sass unit incompatibility
  }

  section .container .card .content p {
    margin: 0.625rem 0 1.25rem;
    color: #565656;
  }

  section .container .card-inner {
    position: relative;
    width: inherit;
    height: 8.75rem;
    background: var(--clr);
    border-radius: 1.25rem;
    border-bottom-right-radius: 0;
    overflow: hidden;
  }

  section .container .card-inner .box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 1.25rem;
    overflow: hidden;
  }

  section .container .card-inner .box .imgBox {
    position: absolute;
    inset: 0;
  }

  section .container .card-inner .box .imgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  section .container .card-inner .box .icon {
    position: absolute;
    bottom: -0.375rem;
    right: -0.375rem;
    width: 6rem;
    height: 6rem;
    background: var(--clr);
    border-top-left-radius: 50%;
  }

  section .container .card-inner .box .icon:hover .iconBox {
    transform: scale(1.1);
  }

  section .container .card-inner .box .icon::before {
    position: absolute;
    content: "";
    bottom: 0.375rem;
    left: -1.25rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem #fff;
  }

  section .container .card-inner .box .icon::after {
    position: absolute;
    content: "";
    top: -1.25rem;
    right: 0.375rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem var(--clr);
  }

  section .container .card-inner .box .icon .iconBox {
    position: absolute;
    inset: 0.625rem;
    background: #1882d1db;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }

  section .container .card-inner .box .icon .iconBox span {
    color: #fff;
    font-size: 1.5rem;

    .mdi:before {
      line-height: 100%;
      font-size: 19px;
      font-size: 43px;
    }
  }
}

.Card-Selection-CSS {
  * {
    box-sizing: border-box;
  }

  /*Delays displaing the cards to avoid animating all the SVG on page load
Change Increase the animation time if needed*/

  animation: show-cards 1.2s;
  animation-fill-mode: forwards;

  /*Grid for testing*/
  .all-cards {
    padding: 12%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-auto-rows: minmax(198px, auto);
    grid-row-gap: 56%;
    grid-column-gap: 29%;
  }

  /* Hide default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .custom-checkbox {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 16px;
    padding-top: 20%;
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 5%;
    box-shadow: 0 0 0 2px #d3d3d3;
    cursor: pointer;
    transition: 0.3s;
  }

  .card-label {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 18px;
    color: #1f375b;
    text-align: center;
    padding: 30px 0 0 0;
  }

  svg {
    overflow: visible;
  }

  /*SVG Line class style*/
  .st0 {
    stroke: #5ca3d8;
    stroke-width: 2px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 320;
    stroke-dashoffset: 0;
  }

  /*Hover Card animation*/
  .container:hover input ~ .custom-checkbox {
    transform: scale(1.05);
    box-shadow: 0 0 0 6px #b0dae9;
  }

  /*Click card bg color*/
  .container input:checked ~ .custom-checkbox {
    background-color: #00a3d7;
  }

  /*Label color change*/
  .container input:checked ~ .custom-checkbox .card-label {
    color: White;
  }

  /*SVG Line checked animation*/
  .container input:checked ~ .custom-checkbox .st0 {
    animation: white-lines 5s;
    animation-fill-mode: both;
  }

  /*SVG Line unchecked animation*/
  .container input:not(:checked) ~ .custom-checkbox .st0 {
    animation: blue-lines 5s;
    animation-fill-mode: both;
  }

  /*SVG bounce checked animation*/
  .container input:checked ~ .custom-checkbox svg {
    animation: bounce 0.4s;
    animation-fill-mode: both;
  }

  /*SVG bounce unchecked animation*/
  .container input:not(:checked) ~ .custom-checkbox svg {
    animation: bounce-back 0.4s;
    animation-fill-mode: both;
  }

  /*Drawing white lines*/
  @keyframes white-lines {
    from {
      stroke-dashoffset: 320;
      stroke: white;
    }

    to {
      stroke-dashoffset: 0;
      stroke: white;
    }
  }

  /*Drawing blue lines*/
  @keyframes blue-lines {
    from {
      stroke-dashoffset: 320;
      stroke: #5ca3d8;
    }

    to {
      stroke-dashoffset: 1;
      stroke: #5ca3d8;
    }
  }

  /*SVG bounce checked*/
  @keyframes bounce {
    0% {
      transform: scale(1.1);
    }

    50% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1.1);
    }
  }

  /*SVG bounce un checked*/
  @keyframes bounce-back {
    0% {
      transform: scale(1.1);
    }

    50% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1.1);
    }
  }

  @keyframes show-cards {
    0% {
      visibility: hidden;
    }

    99% {
      visibility: hidden;
    }

    100% {
      visibility: visible;
    }
  }
}

.trash-workflow {
  position: absolute;
  top: 7px;
  cursor: help;
  font-size: 15px;

  .mdi:before {
  }

  .mdi:before {
    color: red;
    line-height: 100%;
    font-size: 15px;
  }
}

.edit-workflow {
  position: absolute;
  top: 7px;
  cursor: help;
  font-size: 15px;

  .mdi:before {
  }

  .mdi:before {
    color: rgb(79, 149, 235);
    line-height: 100%;
    font-size: 15px;
  }
}
.WorkFlow-Details-wrapper {
  .autocomplete-wrapper .autocomplete .input-wrapper {
    margin-bottom: 0;
    max-width: 190px;
  }

  .label-wrapper {
    font-size: 11px;
  }

  display: flex;
  width: 100%;

  .client-segmentation-item-wrapper {
    display: flex;
    margin-top: 17px;
  }

  .client-segmentation-section {
    margin-right: 15px;
    max-width: 190px;
  }

  .icon-box-slider {
    text-align: center;
    max-width: 1500px;
    margin: revert-layer;
  }
}

.FilterSectionView-view-wrapersAddCondationLeadForm {
  overflow: hidden;
  max-height: 26rem;
}

.icon-box-slider {
  text-align: center;
  max-width: 924px !important;
  margin: 0 auto;
}
