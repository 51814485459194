//========= Table Styles Override ==========
.ProposalFormContainer .table-responsive {
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background: #fff;

  //head
  .MuiTableCell-head {
    border-bottom: 1px solid #eaecf0;
    background: #f9fafb !important;
    height: 44px;
    color: #475467de !important;
    font-weight: 600 !important;
    line-height: 18px;
  }

  .MuiTableCell-head:first-child {
    border-top-left-radius: 10px !important;
  }

  .MuiTableCell-head:last-child {
    border-top-right-radius: 10px !important;
  }


  //body
  .MuiTableCell-body {
    height: 84px !important;
    background-color: #fff !important;
    border-bottom: 1px solid #eaecf0;
    color: #101828de !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px;
  }


  //checkboxes

  .checkbox-wrapper {
    .MuiSvgIcon-root {
      path {
        fill: #7f6944;
      }
    }

    .i-unchecked {
      border: 1px solid #d0d5dd !important;
      width: 18px !important;
      height: 18px !important;
    }

    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
      .mdi {
        border-radius: 0.4rem;
        background-color: #7f6944 !important;
        height: 20px;
        width: 20px;
      }

      .mdi::before {
        font-size: 14px;
      }
    }

    &:hover {
      background-color: unset !important;
    }
  }
}
