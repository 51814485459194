.PropertyNavigationBar {
    display: flex;
    gap: 12px;
    overflow-y: auto;
    padding-bottom: 8px;

    .NavigationBar_Item {
        display: flex;
        padding: 2px 12px;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        cursor: pointer;
        flex-shrink: 0;
        
        span {
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
        }

        &.active {
            border: 1.5px solid #7F6944;
            background: #F4F1ED;
            span {
                color: #635235;
            }
        }

        &.available {
            border: 1px solid #d0d5dd;
            background: #f9fafb;
            span {
                color: #344054;
            }
        }
        &.disabled {
            border: 1px solid #D0D5DD;
            background: #F9FAFB;
            span {
                color: #98A2B3;
            }
        }
    }
}