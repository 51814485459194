.SmartProposal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #fcfcfd;
  min-height: 92vh;

  .PageHeading {
    margin-bottom: 0 !important;
  }

  .SmartProposal_layout {
    display: grid;
    grid-template-columns: 352.16px 3fr;
    gap: 32px;
  }

  .SmartProposal_content {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
  }

  .SmartProposal_content > div:first-child {
    height: 52px;
  }

  .SmartProposal_box {
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  [data-activeStep="MultiUnitsDetails"] {
    display: block;
    padding: 0;
    flex-direction: initial;
    align-items: initial;
    gap: 0;
    align-self: auto;
    border-radius: 0;
    border: none;
    background: transparent;
    box-shadow: none;
  }

  .SmartProposal__nav_box {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .SmartProposal__nav{
    position: absolute;
    top: 24px;
    left: 246px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;

    p {
      font-size: 26px;
      font-weight: 600;
      line-height: 38px; 
      margin: 0;
    }
    span {
      min-width: 34px;
      border-radius: 9999px;
      border: 1px solid #CABFAC;
      padding: 2px 8px;
      height: fit-content;
      background: #F4F1ED;
      color: #635235;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
    }
  }
}
